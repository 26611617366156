import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Wrap from '../layout/Wrap';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import Main from "../main";
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import BG from '../_app/cuchillo/layout/Background';
import { WinSidemenu } from '../windows/Sidemenu';

export default class Default extends Page {
    constructor() {
        super();
        Videos.init();
        Acordions.init();
    }

    _show() {
        EventDispatcher.dispatchEvent(Page.ON_SHOW);
        requestAnimationFrame(() => {
            BG.changePalette(this.color, ()=> {
                if(this.isFirstTime) {
                    Main.hidePreload(()=>{this.show__effect();});
                } else {
                    this.show__effect();
                }
            });
        });
    }

    //SHOW
    beforeShow() {
        Interaction.options.drag = false;
        Scroll.init(Scroll.AXIS_Y, { smooth: !isMobile, multiplicator: 1 });
        Scroll.setScrollbar(new Scrollbar());
        Scroll.start();
    }

    show__effect() {
        Wrap.show(() => { this.afterShow() });
    }

    afterShow() {
        super.afterShow();
    }

    //HIDE
    beforeHide() {
        WinSidemenu.hide();
    }

    hide__effect() {
        Scroll.hide();
        Wrap.hide(() => { this.afterHide(); });
    }

    afterHide() {
        Scroll.dispose();
        super.afterHide();
    }

    //RESIZE
    resize() {
        super.resize();
        Acordions.resize();
    }

    //LOOP
    loop() {
        if (this._isActive) {
            super.loop();
        }
    }

    doItemsIntoDivs(item, split) {
        item[split].map(slice => {
            const divLine = document.createElement('div');
            divLine.classList.add('div-line');
            slice.parentNode.appendChild(divLine);
            divLine.appendChild(slice);
        });
    }

}

ControllerPage._addPage("default", Default)