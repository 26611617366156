import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Power4, Expo, gsap } from 'gsap';
import SplitText from 'gsap/SplitText';
import { GetBy } from '../_app/cuchillo/core/Element';
import ScrollItem__Default from './ScrollItem__Default';
class ScrollItem__NextProject extends ScrollItem__Default {
    _slider;
    _label;
    _title;

    timeLine;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._label = GetBy.selector(".next-project .label", this.__link);
        this._title = GetBy.selector(".next-project .title", this.__link);

        this.setupAnimation();

        this.onShow = () => {
            this.timeLine.restart();
        };
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    setupAnimation() {

        const split_label = new SplitText(this._label, { type: "lines, words " });
        const split_title = new SplitText(this._title, { type: "lines, words " });

        this.doItemsIntoDivs(split_label, 'lines');
        this.doItemsIntoDivs(split_title, 'lines');

        this.timeLine = gsap.timeline({ paused: true });


        //V1
        let delay = 0.5;

        for (var i = 0; i < (split_label.lines.length); i++) {
            this.timeLine.from(split_label.lines[i], { duration: 1.2 + .1 * i, ease: Power4.easeOut, y: (split_label.lines[0].offsetHeight * 7.2), x: -(split_title.lines[0].offsetHeight * 4.5), force3D: true, opacity: 0 }, delay +
                i * 0.3);
        }

        delay = 0.5;
        for (var i = 0; i < (split_title.lines.length); i++) {
            this.timeLine.from(split_title.lines[i], { duration: 1.5 + .1 * i, ease: Expo.easeOut, y: (split_title.lines[0].offsetHeight * 7.5), x: -(split_title.lines[0].offsetHeight * 4.5), force3D: true, opacity: 0 }, delay +
                i * 0.3);
        }



    }

    resize(width, height) {
        super.resize(width, height);
    }


    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('next-project', ScrollItem__NextProject);