import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap, Expo } from "gsap";
import ScrollItem__Default from './ScrollItem__Default';
import { GetBy } from '../_app/cuchillo/core/Element';


class ScrollItem__ProjectImage extends ScrollItem__Default {

    _figure;
    _m1 = 0;
    _m2 = 0;
    _m3 = 100;
    _m4 = 0;
    _m5 = 100;
    _m6 = 100;
    _m7 = 0;
    _m8 = 100;

    get m1() {return this._m1;}
    get m2() {return this._m2;}
    get m3() {return this._m3;}
    get m4() {return this._m4;}
    get m5() {return this._m5;}
    get m6() {return this._m6;}
    get m7() {return this._m7;}
    get m8() {return this._m8;}

    set m1(__n) {this._m1 = __n}
    set m2(__n) {this._m2 = __n}
    set m3(__n) {this._m3 = __n}
    set m4(__n) {this._m4 = __n}
    set m5(__n) {this._m5 = __n}
    set m6(__n) {this._m6 = __n}
    set m7(__n) {this._m7 = __n}
    set m8(__n) {
        this._m8 = __n;
        this._figure.style.clipPath = `polygon(${this._m1}% ${this._m2}%, ${this._m3}% ${this._m4}%, ${this._m5}% ${this._m6}%, ${this._m7}% ${this._m8}%)`;
    }
    
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._figure = GetBy.selector("figure", this.item)[0];
    }

    mouseDown() {
        gsap.killTweensOf(this);
        gsap.to(this, {m1:10,m2:5,m3:90,m4:5,m5:90,m6:95,m7:10,m8:95,
          duration:.4,
          ease:Expo.easeOut});
    }

    mouseUp() {
        gsap.killTweensOf(this);
        gsap.to(this,{m1:0,m2:0,m3:100,m4:0,m5:100,m6:100,m7:0,m8:100,
          duration:2, 
          ease:Expo.easeOut});
    }
}

Scroll._registerClass('project-image', ScrollItem__ProjectImage);