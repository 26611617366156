import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Metrics } from "assets/scripts/_app/cuchillo/core/Metrics";

export default class ScrollItem__Default extends VScroll_Item {

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
    doItemsIntoDivs(item, split) {
        item[split].map(slice => {
            const divLine = document.createElement('div');
            divLine.classList.add('div-line');
            slice.parentNode.appendChild(divLine);
            divLine.appendChild(slice);
        });
    }

}

Scroll._registerClass('default', ScrollItem__Default);