import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Power4, gsap } from 'gsap';
import SplitText from 'gsap/SplitText';
import { GetBy } from '../_app/cuchillo/core/Element';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__FooterProject extends ScrollItem__Default {
    _slider;
    _body;
    _title;
    _separator;

    timeLine;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._body = GetBy.selector(".footer-project .body", this.__link);
        this._title = GetBy.selector(".footer-project .title", this.__link);
        this._separator = GetBy.selector(".footer-project .separator", this.__link);

        this.setupAnimation();

        this.onShow = () => {
            this.timeLine.restart();
        };
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    setupAnimation() {
        const split_body = new SplitText(this._body, { type: "lines, words " });
        const split_title = new SplitText(this._title, { type: "lines, words " });

        this.doItemsIntoDivs(split_body, 'lines');
        this.doItemsIntoDivs(split_title, 'lines');

        this.timeLine = gsap.timeline({ paused: true });

        let delay = 0;

        for (var i = 0; i < (this._separator.length); i++) {
            this.timeLine.from(this._separator[i], { duration: 1.2, scaleX: 0, ease: "power2.inOut" }, delay +
                i * 0.3);
        }

        delay = 0.2;
        for (var i = 0; i < (split_title.lines.length); i++) {
            this.timeLine.from(split_title.lines[i], { duration: 1.2 + .1 * i, ease: Power4.easeOut, y: (split_title.lines[0].offsetHeight * 1.5), force3D: true }, delay +
                i * 0.3);
        }
        delay = 1;

        for (var i = 0; i < (split_body.lines.length); i++) {
            this.timeLine.from(split_body.lines[i], { duration: 1.2 + .1 * i, ease: Power4.easeOut, y: (split_body.lines[0].offsetHeight * 1.2), force3D: true }, delay +
                i * 0.3);
        }
    }

    resize(width, height) {
        super.resize(width, height);
    }


    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('footer-project', ScrollItem__FooterProject);