import { Power4, gsap } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import SplitText from 'gsap/SplitText';
import { GetBy } from '../_app/cuchillo/core/Element';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__ServiceTitles extends ScrollItem__Default {
    _title;
    _quote;
    _op;
    timeLine;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._title = GetBy.class("headline", __link);
        this._quote = GetBy.selector(".__quote p", __link);
        this._op = GetBy.class("__op", __link);

        this.setUpAnimation();

        this.onShow = () => {
            this.timeLine.restart();
        };
        /*
        this.onHide = () => {};
        */

        this.onMove = () => {}
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    setUpAnimation() {
        const split_title = new SplitText(this._title, { type: "chars" });
        const split_quote = new SplitText(this._quote, { type: "lines, words " });

        this.doItemsIntoDivs(split_title, 'lines');
        this.doItemsIntoDivs(split_quote, 'lines');

        this.timeLine = gsap.timeline({ paused: true });

        let delay = 0;

        for (var i = 0; i < (split_title.chars.length); i++) {
            this.timeLine.from(split_title.chars[i], { duration: 1.5, ease: Power4.easeOut, force3D: true, rotationY: -20 - (5 * i), z: 0 + 20 * i, opacity: 0 }, delay + i * 0.05);
        }

        delay = .4;
        for (var i = 0; i < (split_quote.lines.length); i++) {
            this.timeLine.from(split_quote.lines[i], { duration: 1.2 + .1 * i, ease: Power4.easeOut, y: (split_quote.lines[0].offsetHeight * 1.2), force3D: true }, delay + i * 0.05);
        }
        
        delay = .8;
        this.timeLine.from(this._op, { duration: 1.5, ease: Power4.easeOut, opacity: 0 }, delay);
    }

    resize(width, height) {
        super.resize(width, height);
    }


    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('service-titles', ScrollItem__ServiceTitles);