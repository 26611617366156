import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Metrics } from "assets/scripts/_app/cuchillo/core/Metrics";
import { Power4, gsap } from 'gsap';
import SplitText from 'gsap/SplitText';
import { GetBy } from '../_app/cuchillo/core/Element';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__Quote extends ScrollItem__Default {
    _slider;
    _quote;
    timeLine;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._quote = GetBy.class("quote", __link);

        this.setupAnimation();

        this.onShow = () => {;
            this.timeLine.restart();
        };
        /*
        this.onHide = () => {};
        */
        this.onMove = () => {}
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    setupAnimation() {
        this.timeLine = gsap.timeline({ paused: true });

        const split_quote = new SplitText(this._quote, { type: "lines" });

        this.doItemsIntoDivs(split_quote, 'lines');

        let delay = .2;

        for (var i = 0; i < (split_quote.lines.length); i++) {
            this.timeLine.from(split_quote.lines[i], { duration: 1.2 + .1 * i, ease: Power4.easeOut, y: (split_quote.lines[0].offsetHeight * 1.2), force3D: true }, delay + i * 0.05);
        }
    }

    resize(width, height) {
        super.resize(width, height);
    }


    dispose() {
        super.dispose();
    }


}

Scroll._registerClass('quote', ScrollItem__Quote);