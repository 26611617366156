import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Background from '../_app/cuchillo/layout/Background';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Wrap from '../layout/Wrap';
import { isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import EventDispatcher from "../_app/cuchillo/core/EventDispatcher";
import Default from "./Default";
import Main from "../main";
import { Interaction } from '../_app/cuchillo/core/Interaction';

export default class Projects extends Default {
    constructor() {
        super();
    }

    // OVERRIDE PARA DESACTIVAR EL CAMBIO DE COLOR
    _show() {
        EventDispatcher.dispatchEvent(Page.ON_SHOW);

        if (this.isFirstTime) {
            if (isTouch) {
                Wrap.directShow();
            }

            Main.hidePreload(() => { this.show__effect(); });
        } else {
            this.show__effect();
        }
    }

    // SHOW
    beforeShow() {
        Interaction.options.drag = false;
        Scroll.init(Scroll.AXIS_Y, { smooth: !isMobile, multiplicator: 1 });
        Scroll.start();

        Background.nextPalette();
    }

    show__effect(__call) {
        if(isTouch && !this.isFirstTime) {
            Wrap.show(()=> {
                this.afterShow();    
            })
        } else {
            Wrap.directShow();
            this.afterShow();
        }
    }

    afterShow() {
        super.afterShow();
    }
    
    //HIDE
    beforeHide() {
        super.beforeHide();
        Scroll.stop();
    }

    hide__effect() {
        Scroll.hide();
        Wrap.hide(() => { this.afterHide(); });
    }

    afterHide() {
        Scroll.dispose();
        super.afterHide();
    }

    //RESIZE
    resize() {
        super.resize();
    }

    //LOOP
    loop() {
        if (this._isActive) {
            super.loop();
        }
    }

}

ControllerPage._addPage("proyectos", Projects)