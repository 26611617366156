import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

import { Keyboard } from './_app/cuchillo/core/Keyboard';
import { Basics, isDebug, isMobile, isTouch } from './_app/cuchillo/core/Basics';
import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Statics } from './_app/cuchillo/utils/Statics';
import Guides from './_app/cuchillo/utils/Guides';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';
import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import { Metrics } from './_app/cuchillo/core/Metrics';
import Default from './pages/Default';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Project from './pages/Project';
import Estudio from './pages/Estudio';
import Legal from './pages/Legal';
import Services from './pages/Services';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import Page from './_app/cuchillo/pages/Page';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { Power2, gsap } from "gsap";
import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import { WinSidemenu } from './windows/Sidemenu';
import Cursor from './_app/cuchillo/cursor/Cursor';

import Wrap from './layout/Wrap';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Loading from './layout/Loading';
import BG from './_app/cuchillo/layout/Background';
import Cookies from './windows/Cookies';
import Header from './layout/Header';

import { ScrollItem__Default } from './scroll/ScrollItem__Default';
import { ScrollItem__Team } from './scroll/ScrollItem__Team';
import { ScrollItem__Quote } from './scroll/ScrollItem__Quote';
import { ScrollItem__Headline } from './scroll/ScrollItem__Headline';
import { ScrollItem__BlockImagesText } from './scroll/ScrollItem__BlockImagesText';
import { ScrollItem__Footer } from './scroll/ScrollItem__Footer';
import { ScrollItem__FooterProject } from './scroll/ScrollItem__FooterProject';
import { ScrollItem__NextProject } from './scroll/ScrollItem__NextProject';
import { ScrollItem__SliderImage } from './scroll/ScrollItem__SliderImage';
import { ScrollItem__ProjectImage } from './scroll/ScrollItem__ProjectImage';
import { ScrollItem__Contact } from './scroll/ScrollItem__Contact';
import { ScrollItem__ProjectList } from './scroll/ScrollItem__ProjectList';
import { ScrollItem__ServiceTitles } from './scroll/ScrollItem__ServiceTitles';
import { ScrollItem__ImageTextLeftRight } from './scroll/ScrollItem__ImageTextLeftRight';
import { ScrollItem__SliderScrollHorizontal } from './scroll/ScrollItem__SliderScrollHorizontal';
import { ScrollItem__PressList } from './scroll/ScrollItem__PressList';
import { VTeam } from './scroll/VTeam';
import { VSliderImage } from './scroll/VSliderImage';
import { GetBy } from './_app/cuchillo/core/Element';
import { Ease } from './_app/cuchillo/utils/Ease';

export default class Main {

    static scrollbar;
    static stats;
    static domPreloader;

    static init() {
        Basics.id = "in56_v009"; // ID para cookies

        Header.init();

        Metrics.init(() => Main.resize()); // Tamaños y resize
        Keyboard.enable(); // ESC para cerrar ventana
        Accessibility.init(); // Utils accesibilidad
        Statics.init(); // Si estamos en debug pinta un FPS counter
        Interaction.init({ ajax: true, drag: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
        ControllerWindow.init(); // Control ventanas

        BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
        InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
        Cookies.init(); // Checkea y saca el aviso de cookies
        Cursor.init(document.body, {
            color: "#000000",
            fontStyle: {
                size: 16,
                fontFamily: "SweetSansProMedium"
            }
        }, { size: 0 }, { alpha: 0, size: 0 });

        Cursor.icons = [
            { id: "show", src: "/assets/cursor/icon-in.svg", size: 100 },
            { id: "show-white", src: "/assets/cursor/icon-in--white.svg", size: 100 },
            { id: "play", src: "/assets/cursor/icon-play.svg", size: 100 },
            { id: "pause", src: "/assets/cursor/icon-pause.svg", size: 100 },
            { id: "muted", src: "/assets/cursor/icon-muted.svg", size: 100 },
        ]

        this.domPreloader = document.getElementById('Preloader');

        LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
        LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
        LoaderController.onComplete = () => Main.setup();

        this.doCuchilloInfo();
        this.setWorker();

        Guides.init();
        Guides.add({ cols: 12, gap: "12fpx", padding: "30fpx", enabled: false });

        // LOOP
        if (isDebug) {
            gsap.ticker.add(() => { Main.loopDebug(); });
        } else {
            gsap.ticker.add(() => { Main.loop(); });
        }

        Main.showPreload();
    }

    static showPreload() {
        const t1 = GetBy.class("__preloaderT1")[0];
        const t2 = GetBy.class("__preloaderT2")[0];
        const t3 = GetBy.class("__preloaderT3")[0];

        gsap.to(t1, { duration: 1, delay:0, y: 0, force3D: true, ease: Ease.EASE_CUCHILLO_IN_OUT });
        gsap.to(t2, { duration: 1, delay:.3, y: 0, force3D: true, ease: Ease.EASE_CUCHILLO_IN_OUT });
        gsap.to(t3, { duration: 1, delay:.5, y: 0, force3D: true, ease: Ease.EASE_CUCHILLO_IN_OUT,
            onComplete: () => {
                LoaderController.init();
            }
        });
    }

    static hidePreload(__call) {
        const delay = 0;
        const line = GetBy.class("__preloaderLine")[0];
        // HIDE PRELOAD

        gsap.to(line, {alpha:0, duration:.4, delay:delay, force3D:true, ease:Power2.easeIn });

        gsap.to(this.domPreloader, {
            duration: .5,
            alpha: 0,
            delay: delay + .4,
            onComplete: () => {
                this.domPreloader.style.display = "none";
                if(__call) __call();
            }
        });
    }


    static setup() {
        this.setupEvents();
        // INIT PAGE
        ControllerPage.init(Wrap.mainholder);
    }

    static setupEvents() {
        EventDispatcher.addEventListener(Page.ON_SHOW, () => {
            Cursor.start();
            Loading.stop();
        });
        EventDispatcher.addEventListener(Page.ON_HIDE, () => {
            Cursor.hide();
        });
        EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
            Loading.start();
        });

        EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
        EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
    }

    static resize() {
        Guides.resize();
        Header.resize();
        InterfaceCanvas.resize();
        ControllerPage.resize();
    }

    static loop() {
        ControllerPage.loop();
        Header.loop();
        InterfaceCanvas.loop();
        if(!isTouch) Cursor.loop();

        if (Scroll.isScrolling) Scroll.loop();
    }

    static loopDebug() {
        Statics.begin();
        this.loop();
        Statics.end();
    }

    static doCuchilloInfo() {
        console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
        console.log('⟶ http://cuchillo.studio');
        console.log('⟶ https://www.instagram.com/_cuchillo');
        console.log('⟶ https://twitter.com/somoscuchillo');
        console.log('⟶ https://twitter.com/mr__corrales');
        console.log('');
        console.log('Gsap by Greenshock');
        console.log('⟶ https://greensock.com');
        console.log('');
        console.log('Font: Fraktion Mono by Pangram Pangram');
        console.log('⟶ https://pangrampangram.com/products/fraktion');
        console.log('');
        console.log('SVGOMG');
        console.log('⟶ https://jakearchibald.github.io/svgomg/');
        console.log('');
        console.log('Favicon Generator');
        console.log('⟶ https://realfavicongenerator.net');
    }

    static setWorker() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(function() {});
        }
    }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    Main.init();
} else {
    document.addEventListener('DOMContentLoaded', Main.init);
}