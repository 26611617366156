import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { FormValidator } from '../_app/cuchillo/forms/FormValidator';
import { WinMessage } from '../_app/cuchillo/windows/Message';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
// import { Ease } from '../_app/cuchillo/utils/Ease';

class ScrollItem__Contact extends VScroll_Item {
  _form;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    gsap.set(this.item, {
      opacity: 0
    });

    this.onShow = () => {
      gsap.to(this.item, {
        opacity: 1,
        ease: Power2.easeOut,
        duration: 1,
        delay: 1
      });

      const form = GetBy.class('__form', this.item)[0];
      // const msg_ok = form.dataset.msgOk;
      // const msg_nok = form.dataset.msgNok;
      // const linkThanks = msg_ok.startsWith("/")? msg_ok : null;

      this._form = new FormValidator(form);

      // this._form = new FormValidator(
      //   form,
      //   (status) => {
      //     if (status === 'ok') {
      //       if(linkThanks) {
      //         ControllerPage.changePage(linkThanks);
      //       } else {
      //         WinMessage.success(msg_ok);
      //       }
      //     } else {
      //       WinMessage.error(msg_nok);
      //     }
      //   }
      // );
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  dispose() {
    super.dispose();

    this._form.dispose();
  }
}

Scroll._registerClass('contact', ScrollItem__Contact);
