import gsap,{ Power3, Expo } from "gsap";
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Wrap from '../layout/Wrap';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { Videos } from '../_app/cuchillo/components/Videos';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import { Ease } from "../_app/cuchillo/utils/Ease";
import BG from "../_app/cuchillo/layout/Background";
import Main from "../main";
import { WinSidemenu } from "../windows/Sidemenu";

export default class Estudio extends Page {

  constructor() {
    super();

    Videos.init();

    this.setupAnimation();
  }

  _show() {
    EventDispatcher.dispatchEvent(Page.ON_SHOW);
    requestAnimationFrame(() => {
        BG.changePalette(this.color, ()=> {
            if(this.isFirstTime) {
                Main.hidePreload(()=>{this.show__effect();});
            } else {
                this.show__effect();
            }
        });
    });
  }

  //SHOW
  beforeShow() {
    Interaction.options.drag = false;
    
  }

  setupAnimation() {

  }

  show__effect() {
    super.show__effect();
  }

  afterShow() {
    Scroll.init(Scroll.AXIS_Y, {smooth:!isMobile, multiplicator:1});
    Scroll.setScrollbar(new Scrollbar());
    Scroll.start();    
    super.afterShow();
  }

  //HIDE
  beforeHide() {
      WinSidemenu.hide();
  }
  
  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => {this.afterHide();});
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
    if(this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage("estudio", Estudio);
