import gsap, { Power3, Expo } from "gsap";
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Wrap from '../layout/Wrap';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import { Ease } from "../_app/cuchillo/utils/Ease";
import BG from "../_app/cuchillo/layout/Background";
import Main from "../main";
import { WinSidemenu } from "../windows/Sidemenu";

export default class Project extends Page {

    _cover;
    _summary;
    _counter;
    _date;
    _oldColor;

    constructor() {
        super();

        this._cover = this.container.querySelector(".header-project figure");
        this._title = this.container.querySelector(".header-project .__title");
        this._counter = this.container.querySelector(".header-project .__counter");
        this._date = this.container.querySelector(".header-project .__date");
        this._words = [...this.container.querySelectorAll(".header-project .__w")];
       
        Videos.init();
        Acordions.init();

        this.setupButtonsColor();

        //QUITAMOS LINK ACTIVO
        const linkActivo = document.querySelectorAll("[aria-current='page']")[0];
        if(linkActivo) linkActivo.removeAttribute("aria-current");
    }

    setupButtonsColor() {
        const buttons = [...this.container.querySelectorAll(".__btnBG")];

        buttons.map(btn => {
            btn.addEventListener(Basics.mouseOver, (e) => {
                const color = btn.getAttribute("data-color");
                if (!this._oldColor) this._oldColor = BG.actualColor;
                BG.changeBG(color);
            });
            btn.addEventListener(Basics.mouseOut, (e) => {
                BG.changeBG(this._oldColor);
            });
        })
    }

    //SHOW
    beforeShow() {
        Interaction.options.drag = false;
        Scroll.init(Scroll.AXIS_Y, { smooth: !isMobile, multiplicator: 1 });
        Scroll.setScrollbar(new Scrollbar());
        Scroll.start();
    }

    // OVERRIDE PARA DESACTIVAR EL CAMBIO DE COLOR
    _show() {
        BG.changePaletteDirect(this.color);
        EventDispatcher.dispatchEvent(Page.ON_SHOW);
        Wrap.directShow();
        if(this.isFirstTime) {
            gsap.set(this._cover,{alpha:1});
            Main.hidePreload(()=>{this.show__effect();});
        } else {
            this.show__effect();
        }
    }

    show__effect(__call) {
        this.afterShow();

        let mainDelay = 0;
        if(!this.isFirstTime) {
            gsap.to(this._cover, { alpha: 1, duration: .3, delay: 0, ease: Power3.easeIn, force3D: true });
            mainDelay = .4;
        }
        gsap.to(this._title, { y: 0, duration: 1, delay: mainDelay, ease: Ease.EASE_CUCHILLO_IN_OUT, force3D: true });
        gsap.to(this._date, { y: 0, duration: 1, delay: mainDelay + .5, ease: Ease.EASE_CUCHILLO_IN_OUT, force3D: true });
        gsap.to(this._counter, { y: 0, duration: 1, delay: mainDelay + .5, ease: Ease.EASE_CUCHILLO_IN_OUT, force3D: true });


        let delay = mainDelay + .7;
        let counter = 0;
        let time = .7;
        this._words.map((item) => {
            counter++;
            gsap.to(item, { alpha: 1, duration: time, delay: delay, ease: Power3.easeIn });
            delay += .01;
            time += .05 * counter;
        });

    }

    afterShow() {
        super.afterShow();
    }

    //HIDE
    beforeHide() {
        WinSidemenu.hide();
    }

    hide__effect() {
        Scroll.hide();
        Wrap.hide(() => { this.afterHide(); });
    }

    afterHide() {
        Scroll.dispose();
        super.afterHide();
    }

    //RESIZE
    resize() {
        super.resize();
        Acordions.resize();
    }

    //LOOP
    loop() {
        if (this._isActive) {
            super.loop();
        }
    }
}

ControllerPage._addPage("project", Project);