import { Power4, gsap } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import SplitText from 'gsap/SplitText';
import ScrollItem__Default from './ScrollItem__Default';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__ImageTextLeftRight extends ScrollItem__Default {
    _title;
    _text;
    _image;
    timeLine;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._title = GetBy.class("__title", __link);
        this._text = GetBy.selector(".__text p", __link);
        this._image = GetBy.class("__image", __link);

        
        this.setUpAnimation();
        
        this.onShow = () => {
            this.timeLine.restart();
        };
        /*
        this.onHide = () => {};
        */

        this.onMove = () => {}
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    setUpAnimation() {
        const split_text = new SplitText(this._text, { type: "lines, words " });

        this.doItemsIntoDivs(split_text, 'lines');

        this.timeLine = gsap.timeline({ paused: true });

        let delay = .8;
        this.timeLine.from(this._image, { duration: 1.5, ease: Power4.easeOut, opacity: 0, scale: 1.2 }, delay);

        delay = 1.4;
        for (var i = 0; i < (split_text.lines.length); i++) {
            this.timeLine.from(split_text.lines[i], { duration: 1.2 + .1 * i, ease: Power4.easeOut, y: (split_text.lines[0].offsetHeight * 1.2), force3D: true }, delay + i * 0.05);
        }
        
        delay = 1.6;
        this.timeLine.from(this._title, { duration: 1.5, ease: Power4.easeOut, opacity: 0 }, delay);
    }

    resize(width, height) {
        super.resize(width, height);
    }


    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('image-text-left-right', ScrollItem__ImageTextLeftRight);