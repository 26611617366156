import gsap, { Power4, Power2 } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import ScrollItem__Default from './ScrollItem__Default';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';

class ScrollItem__ProjectList extends ScrollItem__Default {
    _info;
    _titles;
    _items;
    _hovers;
    _holder;
    _cursor;
    _separators;
    _images;
    _text;

    timeLine;

    _isHover;
    _timerId;
    _rotation = 0;
    _mouse = { x: 0, y: 0 };
    _mouseTMP = { x: 0, y: 0 };

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._isHover = false;

        this._text = GetBy.class("__text", __link);
        this._info = GetBy.class("__info", __link);
        this._titles = GetBy.class("__title", __link);
        this._items = GetBy.class("__item", __link);
        this._separators = GetBy.class("separator", __link);
        this._images = GetBy.class("__images", __link)[0];
        this._cursor = GetBy.selector("#images-holder .__cursor")[0];
        this._holder = GetBy.selector("#images-holder .__holder")[0];
        this._hovers = Array.from(GetBy.selector("#images-holder .__hover"));

        this.setupAnimation();

        this._call = () => { this.loop(); }

        this._mouseEnter = (e) => { this.mouseEnter(e); };
        this._mouseMove = (e) => { this.mouseMove(e); };
        this._mouseLeave = (e) => { this.mouseLeave(e); };

        this.onShow = () => {
           this.timeLine.restart();

            if (!isMobile) {
                gsap.delayedCall(2, () => {
                    gsap.ticker.add(this._call);

                    for (var i = 0; i < (this._items.length); i++) {
                        this._items[i].addEventListener(Basics.moveEvent, this._mouseMove);
                        this._items[i].addEventListener(Basics.mouseOver, this._mouseEnter);
                        this._items[i].addEventListener(Basics.mouseOut, this._mouseLeave);
                    }
                })
            }
        };
        
        this.onHide = () => {
            if (!isMobile) {
                gsap.ticker.remove(this._call);

                for (var i = 0; i < (this._titles.length); i++) {
                    this._titles[i].removeEventListener(Basics.moveEvent, this._mouseMove);
                    this._titles[i].removeEventListener(Basics.mouseOver, this._mouseEnter);
                    this._titles[i].removeEventListener(Basics.mouseOut, this._mouseLeave);
                }
            }
        }
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
    setupAnimation() {
        this.timeLine = gsap.timeline({ paused: true });

        let delay = 0.8;

        for (var i = 0; i < (this._separators.length); i++) {
            this.timeLine.from(this._separators[i], { duration: 1, scaleX: 0, ease: Power2.easeInOut }, delay +
                i * 0.2);
        }

        for (var i = 0; i < (this._info.length); i++) {
            const info = this._info[i]
            this.timeLine.from(info, {
                duration: 1,
                ease: Power4.easeOut,
                opacity: 0,
                onComplete: () => { info.classList.add('animated'); }
            }, delay + i * 0.2);
        }
        
        delay += .3;
        for (var i = 0; i < (this._titles.length); i++) {
            this.timeLine.from(this._titles[i], { duration: 1.5, ease: Power4.easeOut, y: '100%' }, delay +
            i * 0.2);
        }

        delay += .8;
        this.timeLine.from(this._images, {
            duration: 2,
            ease: Power4.easeOut,
            opacity: 0
        }, delay);
        this.timeLine.from(this._text, {
            duration: 1,
            ease: Power4.easeOut,
            opacity: 0
        }, delay);
    }

    resize(width, height) {
        super.resize(width, height);
    }

    dispose() {
        super.dispose();
    }

    mouseEnter (event) {
        if (!this.isHover) {
            this._isHover = true;
            gsap.set(this._holder, { scaleX: .9, scaleY: .9, });
            gsap.to(this._holder, {
                scaleX: 1,
                scaleY: 1,
                opacity: 1,
                duration: .3,
                ease: Power2.easeOut
            });
            clearTimeout(this._timerId);
        }
        
        this._rotation += 90;
        gsap.to(this._holder, {
            rotateZ: this._rotation,
            duration: .7,
            ease: Power2.easeOut
        });

        this._hovers.map(item => { item.parentNode.style.zIndex = 1; });
        gsap.to(this._hovers, {
            opacity: 0,
            duration: .2,
            ease: Power2.easeOut
        });
        const img = GetBy.id(event.target.dataset.target);
        gsap.killTweensOf(img);
        img.parentNode.style.zIndex = 0;
        gsap.set(img, {
            opacity: 1,
            rotateZ: -(this._rotation-100),
        });
        gsap.to(img, {
            rotateZ: -this._rotation,
            duration: .7,
            ease: Power2.easeOut
        });
    }

    mouseMove (event) {
        // const { height } = this._holder.getBoundingClientRect();
        const x = event.clientX / window.innerWidth * 200;
        const y = event.clientY;

        this._mouseTMP = { x, y };
    }
    
    mouseLeave (event) {
        this.mouseMove(event);

        if (this._isHover) {
            this._isHover = false;
            this._timerId = setTimeout(() => {
                gsap.to(this._holder, {
                    opacity: 0,
                    scaleX: .9,
                    scaleY: .9,
                    duration: .4,
                    ease: Power2.easeOut
                });
                gsap.set(this._hovers, {
                    opacity: 0,
                    delay: .5,
                });
            }, 300);
        }
    }

    loop () {
        this._mouse = {
           x: Maths.lerp(this._mouse.x, this._mouseTMP.x, .05),
           y: Maths.lerp(this._mouse.y, this._mouseTMP.y, .08),
        };

        this._cursor.style.transform = CSS.translate3D(this._mouse.x, this._mouse.y, 0);
    }
}

Scroll._registerClass('project-list', ScrollItem__ProjectList);