import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import ScrollItem__Default from './ScrollItem__Default';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { GetBy } from '../_app/cuchillo/core/Element';
import Background from '../_app/cuchillo/layout/Background';

class ScrollItem__SliderScrollHorizontal extends ScrollItem__Default {
    _slider;
    _slides;
    _index;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._slider = new SliderScroll(__link, {
            // onDragStart: () => { __link.classList.add('--dragging'); },
            // onDragEnd: () => { __link.classList.remove('--dragging'); },
            interaction: false,
            hasLimits: false,
        });

        this._slides = GetBy.class('__slide', __link);
        this._index = 0;

        this._call = () => this.loop();

        this.onShow = () => { gsap.ticker.add(this._call); };
        this.onHide = () => { gsap.ticker.remove(this._call); };
        this.onMove = () => {};
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
    dispose() {
        gsap.ticker.remove(this._call);
        this._slider.dispose();
        super.dispose();
    }

    resize(__w, __h) {
        super.resize(__w, __h);
        this._slider.resize();
    }

    loop() {
        this._slider.progress = 1 - this.progressInside;
        this._slider.loop();

        const currentSlide = Maths.precission(Maths.lerp(0, this._slides.length - 1, 1 - this.progressInside), 0);
        
        if (currentSlide !== this._index) {
            this._index = currentSlide;
            this.item.style.setProperty('--progress', `-${currentSlide * 100}%`);
            Background.nextPalette();
        }
    }
}

Scroll._registerClass('slider-scroll-horizontal', ScrollItem__SliderScrollHorizontal);