import Default from "./Default";
import gsap, { Power3, Expo } from "gsap";
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Background from '../_app/cuchillo/layout/Background';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Wrap from '../layout/Wrap';
import { Basics, isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import Cursor from '../_app/cuchillo/cursor/Cursor';
import { Interaction, MrInteraction } from '../_app/cuchillo/core/Interaction';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import { Slider } from '../_app/cuchillo/components/Slider';
import EventDispatcher from "../_app/cuchillo/core/EventDispatcher";
import Main from "../main";
import { SliderScroll } from "../_app/cuchillo/components/SliderScroll";

export default class Home extends Default {
    _text;
    _title;
    _titleActual;
    _slider;
    _images;
    timeLine;
    
    constructor() {
        super();
        this._title = this.container.querySelector(".__title");
        this._text = this.container.querySelector(".__text");
        this._titleActual = this._title.textContent;

        if (isTouch) {
            this.setupSliderMobile();
            C.remove(this.container.querySelector(".widget-projects"));
        } else {
            this.setupItems();
            C.remove(this.container.querySelector(".widget-projects-mobile"));
            this.setupAnimation();
        }
    }

    setupSliderMobile() {
        const domSlider = this.container.querySelector(".widget-projects-mobile");
        domSlider.style.height = Metrics.HEIGHT + "px";

        this._slider = new SliderScroll(domSlider, {
            onDragStart: () => { Cursor.drag = true; },
            onDragEnd: () => { Cursor.drag = false; },
            interaction: true,
            hasScrollbar: false
        });
    
        this._call = () => this._slider.loop();
        gsap.ticker.add(this._call);
        this.addDispose(() => {
            this._slider.dispose();
            gsap.ticker.remove(this._call);
        });
    }

    setupItems() {
        this._images = this.container.querySelectorAll(".__image");
        let zindex = Functions.arrayRandom(["z1", "z1", "z2"]);
        let positions = Functions.arrayRandom(["top", "center", "bottom"]);
        let paddings = Functions.arrayRandom(["margin-top-1", "margin-top-2", "margin-top-3", "margin-top-4", "margin-bottom-1", "margin-bottom-2", "margin-bottom-3", "margin-bottom-4"]);
        let cont = 0;
        let last = "";
        let lastId = "";

        for (let i = 0, j = this._images.length; i < j; i++, cont++) {
            const item = this._images[i];
            const child = item.querySelector("a");
            const image = child.querySelector("img");
            let idGal = item.getAttribute("data-gallery-id");
            const wImg = Number(image.getAttribute("width"));
            const hImg = Number(image.getAttribute("height"));

            //CHECK SIZE
            const ratio = hImg > wImg ? wImg / hImg : 1;
            const ratioWin = Metrics.ASPECT < 1? 1 : Metrics.ASPECT;
            const newSize = (50/ratioWin) * ratio;

            //RANDOM POSITION
            if (cont === 3) {
                cont = 0;
                positions = Functions.arrayRandom(positions);
                zindex = Functions.arrayRandom(zindex);
            }

            if (last === positions[cont]) cont++;

            image.setAttribute("data-scroll-displace", "true");
            child.setAttribute("data-scroll-insider", "true");

            if (positions[cont] === "top") {
                child.setAttribute("data-speed", "-.2");
            } else if (positions[cont] === "center") {
                child.setAttribute("data-speed", ".1");
            } else if (positions[cont] === "bottom") {
                child.setAttribute("data-speed", ".0");
            }

            item.style.width = `${newSize}vw`;

            if (lastId !== idGal) {
                if (i > 0) item.classList.add("first");
                lastId = idGal;

                /* ANT */
                if (i > 0) {
                    items[i - 1].classList.add("last");
                }
            }

            item.classList.add(positions[cont]);
            item.classList.add(zindex[cont]);
            item.classList.add(paddings[Maths.maxminRandom(8, 0)]);
            last = positions[cont]
        }
    }

    setupAnimation() {
        this.timeLine = gsap.timeline({ paused: true });
        let delay = 0;

        for (var i = 0; i < (this._images.length); i++) {
            this.timeLine.from(this._images[i], {
                    duration: 0.5,
                    opacity: 0,
                    ease: 'expo.in-out',
                    force3D: true
                }, delay + i * 0.1)
                .from(this._images[i], {
                    duration: 1,
                    ease: 'expo.in-out',
                    scale: (this._images[i].classList.contains('z2') ? 1.05 : 0.95),
                    force3D: true
                }, delay + i * 0.1);
        }
        delay = 0.3;
        this.timeLine.from(this._title, { duration: .5, ease: 'power4.in-out', force3D: true, opacity: 0 }, delay);

        delay = 0.5;

        this.timeLine.from(this._text, { duration: .5, ease: 'power4.in-out', alpha: 0, force3D: true }, delay);
    }

    // OVERRIDE PARA DESACTIVAR EL CAMBIO DE COLOR
    _show() {
        EventDispatcher.dispatchEvent(Page.ON_SHOW);

        if(this.isFirstTime) {
            if(isTouch) {
                Wrap.directShow();
            }
            Main.hidePreload(()=>{this.show__effect();});
        } else {
            this.show__effect();
        }
    }

    // SHOW
    beforeShow() {
        Interaction.options.drag = true;
        Scroll.init(Scroll.AXIS_X, { smooth: !isMobile, multiplicator: 1, selector: "scroll-item-project" });
        Scroll.setScrollbar(new Scrollbar());
        Scroll.start();

        if (!isTouch) {
            Scroll.engine._items.map(item => {
                item.onShow = () => {
                    const strTitle = item.item.getAttribute("data-title");
                    
                    if (strTitle != this._titleActual) {
                        this._titleActual = strTitle;
                        this._title.textContent = this._titleActual;
                        Background.nextPalette();
                    }
                }
                item.onHide = () => {}
            });

            Interaction.options.onDragStart = () => {
                Cursor.drag = true;
                for (let i = 0; i < Scroll.engine.total_items; i++) {
                    if(Scroll.engine._items[i].mouseDown) Scroll.engine._items[i].mouseDown();
                }
            };

            Interaction.options.onDragEnd = () => {
                Cursor.drag = false;
                for(let i=0; i<Scroll.engine.total_items; i++) {
                    if(Scroll.engine._items[i].mouseUp) Scroll.engine._items[i].mouseUp();
                }
            };
        }
    }

    show__effect(__call) {
        if(isTouch && !this.isFirstTime) {
            Wrap.show(()=> {
                if(this.timeLine) this.timeLine.restart();
                this.afterShow();    
            })
        } else {
            Wrap.directShow();
            if(this.timeLine) this.timeLine.restart();
            this.afterShow();
        }
    }

    afterShow() {
        super.afterShow();
    }
    
    //HIDE
    beforeHide() {
        super.beforeHide();
        Scroll.stop();
    }

    hide__effect() {
        Scroll.stop();
        

        if (Basics.tempValue) {
            gsap.to(this._text, { alpha: 0, duration: .3, ease: Power3.easeIn, force3D: true });
            gsap.to(this._title, { alpha: 0, duration: .4, ease: Power3.easeIn, force3D: true });

            Scroll.engine._items.map(item => {
                if (item.index != Basics.tempValue) {
                    gsap.to(item.item, { alpha: 0, duration: .4, ease: Power3.easeIn,  delay: .1 });
                } else {
                    gsap.to(item.item, {
                        alpha: 0,
                        duration: .4,
                        ease: Power3.easeIn,
                        delay: 1,
                        onComplete: () => {
                            this.hide__effect_next();
                        }
                    });
                }
            });
        } else {
            this.hide__effect_next();
        }
    }

    hide__effect_next() {
        Scroll.hide();
        Wrap.hide(() => { this.afterHide(); });
    }

    afterHide() {
        Scroll.dispose();
        super.afterHide();
    }

    //RESIZE
    resize() {
        super.resize();
    }

    //LOOP
    loop() {
        if (this._isActive) {
            super.loop();
        }
    }

}

ControllerPage._addPage("home", Home)