import { Power2, gsap } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__PressList extends ScrollItem__Default {
    _posts;
    timeLine;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._posts = Array.from(GetBy.class("__post", __link));

        this.setupAnimation();

        this.onShow = () => {;
            this.timeLine.restart();
        };
        /*
        this.onHide = () => {};
        */
        this.onMove = () => {}
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    setupAnimation() {
        this.timeLine = gsap.timeline({ paused: true });

        let delay = .2;
        for (var i = 0; i < this._posts.length; i++) {
            this.timeLine.from(this._posts[i], { duration: 1, ease: Power2.easeOut, opacity: 0 }, delay + i * .3);
        }
    }

    resize(width, height) {
        super.resize(width, height);
    }


    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('press-list', ScrollItem__PressList);