import { gsap, Power0, Power2 } from 'gsap';

import { isTouch } from '../_app/cuchillo/core/Basics';
import Win from '../_app/cuchillo/windows/Window';
import VScroll from '../_app/cuchillo/scroll/VScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { GetBy } from '../_app/cuchillo/core/Element';

import Header from '../layout/Header';

class Sidemenu extends Win {
    options = {
        container: this.container,
        isMain: false
    };

    _separators;
    _texts;
    _info;

  constructor(__container) {
    super(__container, 'sidemenu');

    this.directHide();

    this._texts = Array.from(GetBy.class("__text", __container));
    this._info = Array.from(GetBy.class("__info", __container));
    this._separators = Array.from(GetBy.class("separator", __container));

    /* BUG RESIZE */
    // if (!isTouch) {
    //   this.engine = new VScroll(this.options);
    //   this.engine.addAll('[scroll-sidemenu-item]');
    //   this.engine.resize();

    //   this._call = () => this.loop();
    // }
  }

  show__effect(__d = 0) {
    gsap.set(this.container, { alpha: 0 });
    gsap.set(this._separators, { scaleX: 0 });
    gsap.set(this._texts, { y: '100%' });
    gsap.set(this._info, { opacity: 0 });

    gsap.to(this.container, {
      alpha: 1,
      duration: 0.3,
      ease: Power0.easeOut
    });

    this._separators.map((item, i) => {
      gsap.to(item, {
        scaleX: 1,
        duration: .7,
        delay: 0.05 * i,
        ease: Power2.easeInOut
      });
    });

    this._texts.map((item, i) => {
      gsap.to(item, {
        y: 0,
        duration: 1,
        delay: 0.05 * i,
        ease: Power2.easeInOut
      });
    });

    gsap.to(this._info, {
        opacity: 1,
        duration: .5,
        delay: .6,
        ease: Power2.easeInOut
    });
  }

  hide__effect(__d = 0) {
    this._texts.map((item, i) => {
      gsap.to(item, {
        y: '100%',
        duration: 0.6,
        ease: Power2.easeInOut
      });
    });

    gsap.to(this._info, {
        opacity: 0,
        duration: .3,
        delay: .2,
        ease: Power2.easeInOut
    });

    gsap.to(this.container, {
      alpha: 0,
      delay: 0.3,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

//   actionButtonToggle(item) {
    // super.actionButtonToggle(item);
//   }

  show() {
    Header.showNav();
    super.show();

    if (this.engine) {
      this.enableScroll();
    }
  }

  hide() {
    Header.hideNav();
    super.hide();

    if (this.engine) {
      this.disableScroll();
    }
  }

  directHide() {
    gsap.set(this.container, { alpha: 0 });
    super.directHide();
    gsap.ticker.remove(this._call);
  }

  enableScroll() {
    if (!this.engine.enabled) this.engine.enabled = true;
    gsap.ticker.add(this._call);
    Scroll.stop();
  }

  disableScroll() {
    if (this.engine.enabled) this.engine.enabled = false;
    gsap.ticker.remove(this._call);
    Scroll.start();
  }

  loop() {
    this.engine.loop();
  }

  resize() {
    super.resize();

    // if (this.engine) {
    //   this.engine.resize();
    // }
  }
}

export const WinSidemenu = new Sidemenu(GetBy.id('Sidemenu'));
