import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';

import { gsap } from "gsap";
import ScrollItem__Default from './ScrollItem__Default';
import { Metrics } from '../_app/cuchillo/core/Metrics';

import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import Cursor from '../_app/cuchillo/cursor/Cursor';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__SliderImage extends ScrollItem__Default {
    _call;
    _slider;
    
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
        
        this._slider = new SliderScroll(__link, {
            onDragStart: () => { Cursor.drag = true; },
            onDragEnd: () => { Cursor.drag = false; },
            interaction: true,
            hasScrollbar: false
        });
    
        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    resize(w,h) {
        super.resize(w,h);
        if(this._slider) this._slider.resize();
    }

    loop() {
        if(this._slider) this._slider.loop();
    }

    dispose() {
        if(this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderImage__Item extends VScroll_Item {

    hasHiddenEnabled = false;
    info;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    //update () {
    /* if (this.progress > .5) {
       const p = Math.max(0, Maths.normalize(1, .5, this.progress));
       this._x = this._x + ((Metrics.WIDTH * .5) * p);
     }*/
    /* super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }*/
}

Scroll._registerClass('SliderImage', ScrollItem__SliderImage);